@import "./utils/styles/trackerTable.scss";

* {
  font-family: "Plus Jakarta Sans";
}
.App {
  text-align: center;
  background-color: var(--dark-theme-color);
  display: flex;
  flex-direction: column;
  height: 100vh;
}
:root {
  --dark-theme-color: #22282f;
  --dark-theme-bg-color: #22282f;
  --dark-theme-color-secondary: #0e1114;
  --main-color: #00d8a0;
  --light-main-color: #b0e4d7;
  --main-secondary-color: #00a97d;
  --main-disable-color: #006650;
  --secondary-color: #22282f;
  --black-color: #191919;
  --input-bg-color: #f1f1ef;
  --white-color: #f1f1ef;
  --font-heading-color: #f1f1ef;
  --box-table-border-color: #e9eaea;
  --inputs-border-color: #babcbf;
  --font-body-heading-color: #22282f;
  --font-body-color: #22282f;
  --font-disable-body-color: #ababaa;
  --font-darkgrey-color: #4e5359;
  --button-red: #e54545;
  --button-green: #00a991;
  --btn-disable-color: rgba(255, 255, 255, 0.12);
  --primary-gradient: linear-gradient(90deg, #00d8a0 0%, #00d8a0 100%);
  --tile-gradient: linear-gradient(180deg, #282e36 0%, #222830 100%);
  --title-color: #dbdbd9;
  --menu-color-dark: #0e1114;
  --background-color: #979797;
  --table-font-color: #979797;
  --secondary-button-color: #8ad7c3;
  --tertiary-color: #e8a242;
  --button-text-white: #fefefd;
  --button-disabled-green: #f6f6f6;
  --checkbox-border: #999c9f;
  --submenu-color: #1f242b;
  --footer-background: #f4f4f2;
  --search-background: #fbfbfa;
  --close-icon-background: #e5e5e5;
  --subtitle-color: #6b6f74;
  --success-toast-bg: #e7f5e8;
  --success-toast-text: #0e8b16;
  --progress-low: #e54545;
  --progress-medium: #ffb248;
  --progress-high: #00ad80;
  --drawer-bg: #f9f9f8;
  --dropdown-menu-title: #6b6f74;
  --disable-row-color: #999c9f;
  --Colors-PRIMARY-GREEN-50: #e6fbf6;
  --Colors-PRIMARY-GREEN-200: #b0f3e2;
  --PRIMARY-GREEN-600: #00a278;
  --PRIMARY-GREEN-100: #d9f9f1;
  --PLAIN-WHITE: #ffffff;
  --Colors-PRIMARY-GREEN-400: #00c290;
  --focus-outline: #b0f3e2;
  --collapse-disabled: rgba(171, 171, 170, 0.4);
  --collapse-disabled-text: #656564;
  --secondary-border: #393e45;
  --secondary-background-dark: #1f242b;
  --input-box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1);
  --table-chip-color: #ffcb84;
  --table-chip-bg: #fff7ed;
  --cards-border-radius: 6px;
  --Colors-ERROR-50: #fcecec;
  --table-row-hover-color: #ededea;

  --gannt-progress-early-start: #b5dfb7;
  --gannt-progress-late-start: #ffdab0;
  --gannt-progress-early-finish: #5d9efe;
  --gannt-progress-late-finish: #ffaf54;
  --gannt-progress-on-time: #5ebb64;

  --status-review-border: #90bcfe;
  --status-review-bg: #e7f1ff;
  --status-review-color: #0d6efd;

  --status-done-border: #3fad46;
  --CCH-cch-500: #007ac2;
  --CCH-cch-300: #54a6d6;

  --left-menu-width: 64px;
  --top-header-width: 64px;
  --tab-bottom-bar-color: #32373d;

  --Colors-SECONDARY-400: #3d8bfd;
  --Colors-TERTIARY-400: #ffc16d;
  --Colors-TERTIARY-200: #ffdcab;
  --Colors-ERROR-300: #ef8f8f;
  --Colors-WARNING-400: #ffa033;
  --Colors-SUCCESS-500: #0f9918;

  --global-border: 0.5px solid #babcbf;
  --global-border-secondary: 0.5px solid #999c9f;

  --Graph-Palette-item-3: #4abdc5;
  --Graph-Palette-item-4: #5648f9;
  --Graph-Palette-item-5: #ea6a6a;
  --Graph-Palette-item-6: #ffb248;
  --Graph-Palette-item-7: #45c9c9;

  --success-border-color: #91d095;
  --error-border-color: #f3a9a9;

  --chart-label-color: #8e8e93;

  --global-disable-row-color: #e7e7e7;
  --card-border-global: 16px;
  --border-global: 6px;
  --header-padding-global: 12px 24px 16px;
  --header-seperator-border-global: 0.5px solid #32373d;

  --error-border-color: #ff5e5e;
  --error-box-shadow: 0px 0px 0px 4px rgba(229, 69, 69, 0.1);

  --GRAY-700: #181c21;
  --Colors-PRIMARY-GREEN-50: #e6fbf6;
  // --Colors-CREAM-200: #f9f9f8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.focus-input {
  &:focus-visible {
    /* outline: var(--focus-outline) auto 1px !important;
            outline-offset: 2px !important; */
    outline: 0.5px solid var(--inputs-border-color) !important;
    border: 0.5px solid var(--font-darkgrey-color, #4e5359) !important;
    box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1) !important;
  }
}

.fixed-button-group {
  position: fixed;
  bottom: 0;
  left: var(--left-menu-width);
  padding: 14px 24px;
  width: calc(100% - var(--left-menu-width));
  z-index: 1004;
  border-top: 0.5px solid rgba(78, 83, 89, 0.4);
  background: var(--dark-theme-bg-color);
  box-shadow: 0px -4px 16px 2px rgba(0, 0, 0, 0.08);
}

.inline-button-group {
  margin-top: 32px;
}

.button,
.ant-btn {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 16px;
  color: var(--secondary-color);
  cursor: pointer;
  min-width: 90px;
  border: none;
  white-space: nowrap;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
  &:focus-visible {
    outline: var(--focus-outline) auto 1px !important;
    outline-offset: 2px !important;
  }
}

.export-button {
  background-color: var(--main-color);
  color: var(--font-body-color);
  cursor: pointer;
}
.collapse-button {
  background-color: var(--white-color);
  color: var(--font-body-color);
  font-size: 14px;
  font-weight: 500;
  img {
    filter: invert(100%);
  }
}

.tertiary-button {
  background-color: var(--white-color);
  color: var(--font-body-color);
  font-size: 14px;
  font-weight: 500;
  img {
    width: 20px;
    height: 20px;
  }
}

.cch-button {
  background-color: #007ac2;
  color: var(--button-text-white);
  font-size: 14px;
  font-weight: 600;
}
.secondary-buttons,
.ant-btn-link {
  background-color: var(--white-color) !important;
  color: var(--font-body-color) !important;
  font-size: 14px;
  font-weight: 600;
  border: 0.5px solid var(--inputs-border-color);
  &:hover {
    color: var(--PRIMARY-GREEN-600) !important;
    img {
      filter: invert(39%) sepia(76%) saturate(1998%) hue-rotate(139deg)
        brightness(92%) contrast(101%);
    }
  }
}

.grey-button {
  background-color: #f1f1ef;
  color: var(--font-body-color);
  font-size: 14px;
  font-weight: 500;
  img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    color: var(--PRIMARY-GREEN-600) !important;
    img {
      filter: invert(39%) sepia(76%) saturate(1998%) hue-rotate(139deg)
        brightness(92%) contrast(101%);
    }
  }
}

.table-header-button {
  font-size: 12px;
  height: 36px;
  padding: 16px;
}
.primary-button,
.ant-btn-primary {
  background: var(--primary-gradient) !important;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
  &:hover {
    background: var(--progress-high) !important;
    color: var(--PLAIN-WHITE) !important;
    img {
      filter: invert(95%) sepia(100%) saturate(2%) hue-rotate(138deg)
        brightness(107%) contrast(101%);
    }
  }
}
.primary-button-border,
.ant-btn-primary {
  border: 0.5px solid var(--Colors-PRIMARY-GREEN-500, #00ad80);
  background: var(--Colors-CREAM-200, #f9f9f8);
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  color: var(--Colors-PRIMARY-GREEN-500, #00ad80);
  // box-shadow: none !important;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
  &:hover {
    background: var(--progress-high) !important;
    color: var(--PLAIN-WHITE) !important;
    img {
      filter: invert(95%) sepia(100%) saturate(2%) hue-rotate(138deg)
        brightness(107%) contrast(101%);
    }
  }
}
.brown-button,
.ant-btn-primary {
  background: #f9f9f8 !important;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  opacity: 0.4;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
}

.back-button {
  color: var(--PLAIN-WHITE);
  background-color: var(--dark-theme-bg-color);
  border: none;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    background: var(--progress-high);
  }
}
.white-button {
  font-size: 14px;
  font-weight: 600;
}
.primary-accept {
  background: var(--button-green);
  color: var(--font-body-color);
  font-size: 14px;
  &:disabled {
    background: var(--box-table-border-color);
    color: var(--font-disable-body-color);
    opacity: 1;
  }
}
.primary-accept-selected {
  background: var(--button-green);
  color: var(--font-body-color);
  font-size: 14px;
  font-weight: 600;
  opacity: 0.4;
}
.primary-decline {
  color: var(--PLAIN-WHITE);
  background-color: var(--button-red);
  border: none;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  &:disabled {
    background: var(--box-table-border-color);
    color: var(--font-disable-body-color);
    opacity: 1;
  }
}
.primary-decline-selected {
  color: var(--PLAIN-WHITE);
  background-color: var(--button-red);
  border: none;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  opacity: 0.4;
}
.ant-checkbox-checked .ant-checkbox-inner {
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background-color: var(--main-secondary-color) !important;
  border: none !important;
}

.ant-radio-wrapper:hover {
  .ant-radio-inner {
    border-color: var(--title-color);
  }
}
.ant-radio-disabled {
  opacity: 0.5;
  .ant-radio-inner {
    border: 1.5px solid var(--title-color) !important;
    background-color: var(--button-text-white) !important;
  }
}
.ant-spin-fullscreen-show {
  z-index: 1005;
}
.ant-radio-checked .ant-radio-inner {
  border-color: var(--main-secondary-color) !important;
  background-color: var(--main-secondary-color) !important;
}
.ant-radio-input:focus-visible + .ant-radio-inner {
  outline: var(--focus-outline) auto 1px !important;
  outline-offset: 2px !important;
}
.ant-radio-inner {
  border: 1.5px solid var(--inputs-border-color) !important;
  height: 20px !important;
  width: 20px !important;
  background-color: transparent !important;
}
.ant-radio::after {
  border: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: var(--checkbox-border) !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 2px;
  border: 1.5px solid var(--checkbox-border);
  width: 16px;
  height: 16px;
}
.ant-checkbox .ant-checkbox-inner:after {
  width: 5px;
  height: 8px;
  inset-inline-start: 27%;
}
.ant-input-number-outlined:focus-within {
  border-color: var(--inputs-border-color);
  box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1);
}
.ant-input-number-outlined:hover {
  border-color: var(--font-darkgrey-color);
}
.ant-picker-dropdown {
  z-index: 10000000002 !important;
  .ant-picker-header-view button:hover {
    color: var(--progress-high);
  }
}
.ant-picker-outlined {
  width: 100%;
  border: 0.5px solid var(--inputs-border-color);
  background: var(--drawer-bg);
  border-radius: 4px;
  &:hover {
    border: 0.5px solid var(--font-darkgrey-color);
    background: var(--button-text-white);
  }
}
.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within {
  box-shadow: var(--input-box-shadow);
  border: 0.5px solid var(--font-darkgrey-color);
  background: var(--button-text-white);
}

.ant-picker-cell-range-start,
.ant-picker-cell-range-end {
  .ant-picker-cell-inner {
    background: var(--progress-high) !important;
  }
}
.ant-picker-cell-inner::before {
  border: 1px solid var(--progress-high) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  ):before {
  background: var(--Colors-PRIMARY-GREEN-50) !important;
}
.ant-picker-active-bar {
  background: var(--progress-high) !important;
}
.ant-picker-now-btn {
  color: var(--progress-high) !important;
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: var(--progress-high) !important;
    /* color: var(--white-color) !important; */
  }
}
.ant-popover {
  z-index: 1000000000;
}
.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
  color: var(--progress-high) !important;
}
.ant-input-outlined:focus-within {
  border: 0.5px solid var(--font-darkgrey-color, #4e5359);
  box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1);
}
.ant-input-outlined:hover {
  border: 0.5px solid var(--inputs-border-color);
  background-color: var(--search-background);
}
.ant-input-outlined {
  border: 0.5px solid var(--inputs-border-color);
  background-color: var(--search-background);
}
.ant-switch-checked {
  .ant-switch-inner {
    background: var(--primary-gradient);
  }
  .ant-switch-handle::before {
    background: var(--white-color) !important;
  }
}
.ant-switch .ant-switch-handle::before {
  background: var(--secondary-color);
}
.ant-switch-inner {
  background: var(--white-color);
}
.ant-modal-wrap {
  z-index: 10000000001 !important;
}
.ant-modal-mask {
  z-index: 10000000000 !important;
}
.primary-input {
  height: 48px;
  width: 100%;
  border-radius: 4px;
}
.ant-input {
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 500;
}
.ant-input-password-icon {
  font-size: 16px;
}
.ant-form-item-explain-error {
  text-align: left;
  margin-bottom: 22px;
  font-size: 13px;
  color: var(--button-red);
}
.ant-input-status-error {
  border-color: var(--button-red) !important;
  border-width: 0.5px !important;
}
.ant-table-filter-dropdown {
  .ant-dropdown-menu-title-content {
    color: var(--font-body-color) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    overflow: hidden;
  }
  .ant-dropdown-menu-title-content > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-inline-start: 0 !important;
  }
  .ant-table-filter-dropdown-btns {
    justify-content: flex-end !important;
    border-top: 1px solid var(--box-table-border-color) !important;
    padding: 12px !important;
    gap: 12px;

    .ant-btn-link,
    .ant-btn-primary {
      height: 31px;
      line-height: 150%;
      padding: 5px 16px;
      min-width: auto;
      border-radius: 4px;
    }
  }
}

.ant-table-filter-trigger.active,
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--PRIMARY-GREEN-600) !important;
  background: none;
}
.ant-table-filter-trigger {
  display: block !important;
  color: var(--subtitle-color) !important;
}
.ant-table-column-sorter {
  display: block !important;
  color: var(--subtitle-color) !important;
}
.ant-pagination-item-link,
.ant-pagination-item {
  border-radius: 2px !important;
  background-color: var(--white-color) !important;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    background-color: #b0f3e2 !important;
  }
}
.ant-pagination-item-active {
  background-color: var(--progress-high) !important;
  border-color: var(--progress-high) !important;
}
.ant-pagination-item-ellipsis {
  color: var(--white-color) !important;
}
.ant-pagination-options {
  .ant-select-selector {
    border-radius: 2px !important;
    background-color: var(--white-color) !important;
    .ant-select-selection-item {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):focus-within
  .ant-select-selector {
  box-shadow: var(--input-box-shadow) !important;
  border: 0.5px solid var(--font-darkgrey-color, #4e5359) !important;
}

.custom-select {
  width: 100%;
  /* height: 100%; */
}
.global-select-component {
  .ant-select-selector {
    border: 0.5px solid var(--inputs-border-color) !important;
    border-radius: 4px !important;
    background: var(--drawer-bg);
    gap: 12px;
  }
  .ant-select-selection-item {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-select-arrow {
    img {
      width: 18px;
    }
  }
}

.global-select-component-secondary {
  .ant-select-selection-item {
    font-size: 14px;
    font-weight: 500;
  }
}

.ant-select-selector {
  border-color: var(--inputs-border-color) !important;
  box-shadow: none !important;
  border: 0.5px solid var(--inputs-border-color);
  border-radius: 4px;
  border-width: 0.5px;
  text-align: left;
  &:hover {
    border: 0.5px solid var(--font-darkgrey-color, #4e5359) !important;
  }
}
.ant-select-item-option-selected {
  background-color: var(--white-color) !important;
  color: var(--font-body-color) !important;
}
.ant-select-item-option-active {
  background-color: var(--white-color) !important;
}
.ant-select-dropdown {
  background-color: var(--PLAIN-WHITE);
  border-radius: 4px;
  z-index: 10000000000 !important;
}
/* test css */
.jodit-container {
  height: 700px !important;
}
.notify {
  top: 120px !important;
}

.ant-notification {
  top: 80px !important;
}

.rotate-180 {
  transform: scaleX(-1);
}

.full-width {
  width: 100%;
}
.width-70 {
  width: 70% !important;
}
.width-50 {
  width: 50% !important;
}

.fullscreen-loader {
  z-index: 1000000000000000000;
  .ant-spin-dot {
    color: var(--main-color);
  }
}

.select-placeholder {
  color: var(--subtitle-color);
  font-size: 14px;
  font-weight: 500;
}

.ant-tooltip-global {
  z-index: 1000000000;
  .ant-tooltip-inner {
    line-height: normal;
  }
}
.ant-tooltip-light {
  .ant-tooltip-inner {
    background: var(--white-color);
    color: var(--font-body-color);
  }
  .ant-tooltip-arrow:before {
    background: var(--white-color);
  }
}
.ant-tooltip-dark {
  .ant-tooltip-inner {
    background: var(--dark-theme-bg-color);
    color: var(--white-color);
  }
  .ant-tooltip-arrow:before {
    background: var(--dark-theme-bg-color);
  }
}

.ant-dropdown {
  z-index: 10000000000 !important;
  .ant-dropdown-menu {
    padding: 0;
  }
  .ant-dropdown-menu-item {
    padding: 10px var(--Dimensions-Spacing-lg, 16px) !important;
    height: 40px;
    width: 260px;
  }
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-selected {
    background-color: rgba(233, 234, 234, 0.5) !important;
  }
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--dropdown-menu-title);
    font-size: 13px;
    font-weight: 600;
    line-height: 150%; /* 19.5px */
  }
  /* .ant-btn-sm,
  .ant-btn-sm :hover {
    color: var(--font-body-color) !important;
  } */
}

.green-text-color {
  color: var(--progress-high);
  font-weight: 600;
}
.ant-modal-close:hover {
  background-color: unset !important;
}
.ant-table-column-sorters {
  justify-content: flex-start !important;
  gap: 5px;
}
.ant-picker-range-arrow {
  display: none !important;
}
.ant-radio-button-wrapper-checked {
  border-color: var(--PRIMARY-GREEN-600) !important;
}
.ant-radio-button-checked {
  color: var(--PRIMARY-GREEN-600) !important;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--PRIMARY-GREEN-600) !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--PRIMARY-GREEN-600) !important;
}

.full-screen-modal {
  .ant-modal {
    top: 64px !important;
    left: 64px !important;
    width: calc(100% - 64px) !important;
    height: calc(100% - 64px) !important;
    padding: 0;
    margin: 0;
    .ant-modal-content {
      border-radius: 0;
      height: calc(100vh - 64px);
    }
  }
}
.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wrapped-text {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: transparent;
}
.unauthorized {
  color: var(--white-color);
  font-size: 24px;
  padding: 40px;
}
.e-de-ctn {
  border: none !important;
}
.status-chip {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  .status-dot {
    margin-top: 1px;
    font-weight: 700;
    height: 5px;
    width: 5px;
    border-radius: 50%;
  }
}
.eng-planner-status-chip {
  border-radius: 30px;
  border: 0.5px solid;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  padding: 0px 12px;
  line-height: 19px;
  align-items: center;
  gap: 8px;
  width: max-content;
  height: 24px;
  .chip-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.ant-table-selection-column {
  padding: 16px !important;
}
.ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: var(--table-row-hover-color) !important;
}
.ant-table-cell-row-hover {
  background-color: var(--table-row-hover-color) !important;
}
// .e-rte-content {
//   height: 60vh;
// }
.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background: var(--white-color) !important;
  }
}
.status-chip {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: var(--Dimensions-Spacing-4xs, 1px) var(--Dimensions-Spacing-md, 12px);
  align-items: center;
  .status-dot {
    margin-top: 1px;
    font-weight: 700;
    height: 5px;
    width: 5px;
    border-radius: 50%;
  }
}
.modal-action-buttonGroup {
  display: flex;
  justify-content: end;
  gap: 12px;
  align-items: center;
  width: 100%;
  button {
    height: 40px;
  }
}
.loading-sheet {
  color: var(--white-color);
  text-align: center;
  font-weight: 600;
  height: 100%;
  .loading-skeleton {
    width: 100%;
    height: 100%;
    background-color: var(--input-bg-color);
    border-radius: 6px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column;
  }
  .loading-text {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  .loading-sub-text {
    color: var(--GRAY-400, var(--Colors-GRAY-400, #4e5359));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
  }
}
.ant-select-item-option-content {
  white-space: wrap !important;
}
