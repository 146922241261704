.microapp-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: var(--header-padding-global);
  border-bottom: var(--header-seperator-border-global);

  .title-div {
    display: flex;
    justify-content: space-between;

    .title-text {
      color: var(--white-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
    }
  }

  .engagement-wrapper {
    color: var(--LIGHT_CREAM-600, #dbdbd9);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;

    span {
      color: #e9eaea;
      font-family: "Plus Jakarta Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;
    }
  }

  .custom-tabs {
    .ant-tabs-nav {
      margin: 0;
      display: flex;
      justify-content: start;
      gap: 16px;

      &::before {
        display: none;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 500;
            color: var(--white-color);
            transition: all 0.3s;
            border: 1px solid transparent;
            border-radius: 4px 4px 0 0;
            margin: 0;

            &.ant-tabs-tab-active {
              background-color: var(--dark-theme-color);
              border: 1px solid var(--tab-bottom-bar-color);
              border-bottom: var(--dark-theme-color);

              .ant-tabs-tab-btn {
                color: var(--main-secondary-color);
              }
            }

            &:hover {
              color: var(--white-color-hover, #e0e0e0);
            }
          }

          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
    }
  }
}
