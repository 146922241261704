.control-section.spreadsheet-control {
  /* height: 550px;  */
}
#sb-content .e-spreadsheet .e-tab .e-tab-text {
  display: inherit;
}
.e-spreadsheet .e-ribbon .e-menu-wrapper {
  display: none !important;
}

.e-spreadsheet .e-ribbon {
  border-radius: var(--cards-border-radius) !important;
}
.e-highlight {
  background: rgb(230, 251, 246) !important;
}

.spredSheet-container {
  width: 100%;
  overflow: auto;
  border-radius: 6px;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
  border: none !important;
}

.loading-sheet {
  color: var(--white-color);
  text-align: center;
  font-weight: 600;
  height: 100%;
  .loading-skeleton {
    width: 100%;
    height: 100%;
    background-color: var(--input-bg-color);
    border-radius: 6px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column;
  }
}

.e-spreadsheet .e-cell .e-radio-wrapper {
  margin: 5px;
}

.e-toolbar-item e-template e-active {
  color: #000 !important;
}

.e-spreadsheet .e-ribbon .e-tab .e-tab-header .e-tab-wrap:focus .e-tab-icon,
.e-spreadsheet .e-ribbon .e-tab .e-tab-header .e-tab-wrap:focus .e-tab-text {
  color: var(--main-secondary-color) !important;
}

.e-spreadsheet .e-cell .e-radio-wrapper:first-child {
  margin-left: 0;
}

.e-tab-wrap e-active {
  border-bottom: 2px solid var(--main-secondary-color) !important;
}

.e-spreadsheet .e-cell .e-radio + label .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.material-dark
  .e-custom-spreadsheet.e-spreadsheet
  .e-sheet
  .e-sheet-content
  .e-cell-template
  .e-input-group.e-control-wrapper {
  background-color: rgb(56, 56, 56);
}
.material-dark
  .e-custom-spreadsheet.e-spreadsheet
  .e-sheet
  .e-sheet-content
  .e-cell-template
  .e-radio
  + label
  .e-label {
  color: #000;
}

.material-dark
  .e-custom-spreadsheet.e-spreadsheet
  .e-sheet
  .e-sheet-content
  .e-cell-template
  .e-radio
  + label::before {
  background-color: #fff;
  border-color: #757575;
}

.material-dark
  .e-custom-spreadsheet.e-spreadsheet
  .e-sheet
  .e-sheet-content
  .e-cell-template
  .e-radio:checked
  + label::before {
  border-color: #00b0ff;
}
.material-dark
  .e-custom-spreadsheet.e-spreadsheet
  .e-sheet
  .e-sheet-content
  .e-cell-template
  .e-radio-wrapper {
  padding-right: 10px;
}

.material-dark
  .e-custom-spreadsheet.e-spreadsheet
  .e-sheet
  .e-sheet-content
  .e-cell-template
  .e-btn.e-flat {
  color: #000;
}
.e-spreadsheet .e-selection {
  border-color: var(--main-secondary-color) !important;
}

.e-spreadsheet .e-active-cell {
  border-color: var(--main-secondary-color) !important;
  margin-top: 2px;
}

.e-spreadsheet .e-autofill {
  background-color: var(--main-secondary-color) !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: var(--main-secondary-color) !important;
}

.e-spreadsheet
  .e-ribbon
  .e-tab
  .e-tab-header:not(.e-vertical)
  .e-toolbar-item.e-active {
  border-bottom: 2px solid var(--main-secondary-color) !important;
}

.e-tab .e-tab-header .e-indicator {
  background: var(--main-secondary-color) !important;
}

.e-spreadsheet .e-ribbon .e-tab .e-tab-header .e-tab-wrap:focus {
  background: var(--white-color) !important;
}

.mecius-sheet-container {
  position: relative;
  overflow: hidden;
  .sample-container {
    width: 100%;
    height: 100%;
  }
  table {
    width: 100% !important;
  }
  .sample-spreadsheets {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .gc-no-user-select {
      padding: 0;
    }
  }

  #statusBar {
    bottom: 0;
    height: 25px;
    width: 100%;
    position: relative;
  }

  .options-container {
    float: right;
    width: 280px;
    height: 100%;
    box-sizing: border-box;
    background: #fbfbfb;
    overflow: auto;
  }

  .sample-options {
    z-index: 1000;
  }

  .inputContainer {
    width: 100%;
    height: auto;
    border: 1px solid #eee;
    padding: 6px 12px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
}
.sheet-custom-icon {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.sheet-custom-option-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}
.e-spreadsheet
  .e-ribbon
  .e-menu-wrapper
  ul
  .e-menu-item.e-menu-caret-icon.e-selected {
  background: none !important;
  color: var(--main-secondary-color) !important;
}
.e-menu-wrapper .e-file-menu .e-lib e-keyboard {
  display: none !important;
}
