.decoder-table {
  .ant-table-row {
    padding: 16px !important;
    border: none !important;
  }

  .last-child-row {
    .ant-table-cell {
      background: #fff;
    }
  }

  .ant-table-thead .ant-table-cell {
    padding: 16px !important;
  }

  .ant-table-cell {
    padding: 0px !important;
    margin: 10px !important;
    border-bottom: 0.5px solid #ababaa !important;
    /* background-color: var(--title-color) !important; */
    border-left: 0.5px solid #ababaa !important;
    :before {
      border: none !important;
    }
  }

  .ant-table-cell-custom {
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px;
  }

  .ant-table-cell-custom2 {
    margin-left: 35px !important;
    height: 100%;
    padding: 16px 0;
    border-left: 0.5px solid #ababaa;
    display: flex;
    justify-content: start;
    align-items: center;

    /* . */
  }

  .ant-table-cell-with-append {
    padding: 0 !important;
    border-left: none !important;
  }

  .anticon {
    padding: 0 15px !important;
    /* height: 30px !important; */
    display: block;
  }

  .custom-column-th-cell {
    padding: 16px !important;
  }

  .ant-table-container {
    background-color: var(--title-color) !important;
    border: 0.5ps solid var(--border-color) !important;
  }

  table {
    border: 0.5px solid var(--border-color) !important;
  }

  .ant-table-thead .ant-table-cell {
    padding: 5px 16px !important;
    background-color: var(--white-color) !important;
  }

  .ant-table-thead .ant-table-cell:first-child {
    padding: 5px 40px !important;
  }

  .ant-table-cell .comments {
    /* border-left: 0.5px solid rgb(171, 171, 170); */
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .comments {
    /* font-weight: 400; */
  }

  .ant-table-cell {
    height: 56px;
  }

  .ant-tooltip-inner {
    /* width: 400px; */
  }

  .input:focus {
    outline: none;
    border: 0.5px solid var(--main-secondary-color) !important;
  }

  .tree-checkbox:focus {
    outline: none;
    border: 0.5px solid var(--main-secondary-color) !important;
  }

  .ant-select-selector:hover {
    outline: none;
    border: 0.5px solid var(--main-secondary-color) !important;
  }

  div:focus,
  button:focus {
    border: 1px solid #00a97d;
    outline-style: solid;
    outline-color: #00a97d;
    outline-width: thin;
  }
  .ant-select {
    // max-width: 80% !important;
    float: left !important;
    margin-left: 40px !important;
  }
  .ant-form-item-explain-error {
    margin-left: 40px;
  }
  .ant-select {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 10px;
  }
}
.ant-table-cell-no-children {
  border-left: none !important;
}
.input-drop-wrapper {
  .input-wrapper {
    margin: 0 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-around;

    .input {
      height: 40px;
      width: 100%;
      padding: 15px;
      margin-top: 5px;
    }

    .plus-icon {
      cursor: pointer;
      width: 25px;
    }
  }
}

.rightalign-text {
  width: 100%;
  text-align: right;
}
.highlight {
  box-shadow:
    0 -5px 3px -3px #00a97d,
    0 5px 3px -3px #00a97d;
}
